import React, {useEffect} from 'react'
import {Field, Form} from 'react-final-form'
import {Button} from 'react-bootstrap'
import {useSelector, useDispatch} from 'react-redux'
import ReactSelectAdapter from './reactSelectAdapter'
import Axios from 'axios'

import {useToasts} from 'react-toast-notifications'

import {fetchUsers} from '../actions/users'

const {parseInsertRecord} = require('./utils/utils')

function MatchForm () {
  const {addToast} = useToasts()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.userinfo.nickname)
  const users = useSelector((state) => Object.values(state.users))

  const formattedUsers = users.map((user) => {
    return {label: user.username, value: {username: user.username, score: user.score}}
  })

  useEffect(() => {
    dispatch(fetchUsers())
    // eslint-disable-next-line
  }, [])

  async function onSubmit (values) {
    values.player2 = values.player2.value.username

    const parsedRecord = parseInsertRecord(values)
    try {
      await Axios.post('/api/matches', parsedRecord)
      addToast('Partita aggiunta con successo', {
        appearance: 'success',
        autoDismiss: true
      })
    } catch (error) {
      addToast(`Error while inserting record: ${error.response?.data?.message ?? error}`, {
        appearance: 'error',
        autoDismiss: true
      })
    }
  }

  return (
    <Form
      initialValues={{player1: user}}
      onSubmit={onSubmit}
      render={({handleSubmit, form, submitting, pristine}) => (
        <form onSubmit={event => {
          handleSubmit(event).then(form.reset)
        }}
        >
          <div className='formItem'>
            <Field
              className={'playerInput'}
              component='input'
              disabled
              name='player1'
              required
              type='text'
            />
          </div>
          <div className='formItem'>
            <Field
              className={'playerInput'}
              component={ReactSelectAdapter}
              getOptionLabel={option => `${option.label}: ${option.value.score}`}
              name='player2'
              options={formattedUsers}
              placeholder='nome.cognome'
              required
              type='text'
            />
          </div>
          <div className='formItem scores d-flex justify-content-center'>
            <div className='w-25 p-2'>
              <Field
                className='scoreInput'
                component='input'
                label='ScorePlayer1'
                name='scorePlayer1'
                placeholder='0'
                required
                type='number'
              />
            </div>
            <div className='w-25 p-2'>
              <Field
                className='scoreInput'
                component='input'
                label='ScorePlayer2'
                name='scorePlayer2'
                placeholder='0'
                required
                type='number'
              />
            </div>
          </div>
          <div className='buttons'>
            <Button disabled={submitting || pristine} type='submit'>
              {'Submit'}
            </Button>
          </div>
        </form>

      )}
    />
  )
}

export default MatchForm
