import React from 'react'
import {useParams} from 'react-router-dom'
import useLoadingData from '../hooks/useLoadingData'

import PlayerInfos from './player/player-infos'
import PlayerHeader from './player/player-header'
import PlayerLeaderboardView from './playerLeaderboardView'

import {Card} from 'react-bootstrap'

function Profile () {
  const isLoadingText = 'Loading user infos...'
  const {username} = useParams()
  const [data, isError, isLoading, isErrorText] = useLoadingData('users', username)

  if (isLoading) { return <div>{isLoadingText}</div> }
  if (isError) { return <div>{isErrorText}</div> }

  const {
    name,
    surname,
    username: user,
    score,
    wonMatches,
    lostMatches,
    playedMatches
  } = data
  return (
    <div className='profile'>
      <div className={'playerHeader d-flex w-100 p-2'}>
        <Card className='playerInfos p-3 m-3 h-100'>
          {user &&
            <PlayerHeader
              name={name}
              surname={surname}
              username={user}
            />}
          {data.score &&
            <div className='mt-3'>
              <p>
                {`Score: ${score}`}
              </p>
            </div>}
          {(data.playedMatches != null && data.wonMatches != null) &&
            <div>
              <p>
                <span
                  style={{opacity: '0.5'}}
                >{`(${wonMatches}W-${lostMatches}L) `}
                </span>
                {displayWinRate(playedMatches, wonMatches)}
              </p>
            </div>}

        </Card>
        <Card className='playerLeaderboard p-2 m-3'>
          <PlayerLeaderboardView user={user} />
        </Card>
      </div>

      <PlayerInfos user={data} />
    </div>
  )
}

function displayWinRate (played, won) {
  const ratio = won / played * 100
  const values = getRatioValues(ratio)
  return <span style={{color: values.color}}>{`WR: ${ratio.toFixed(1)}% ${values.emoji}`}</span>
}

function getRatioValues (ratio) {
  if (ratio > 75) return {color: 'lightgreen', emoji: '🔥'}
  else if (ratio > 50 && ratio <= 75) return {color: 'lightsalmon', emoji: '😎'}
  else if (ratio > 25 && ratio <= 50) return {color: 'orange', emoji: '🤨'}

  return {color: 'red', emoji: '🥶'}
}
export default Profile
