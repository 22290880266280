import axios from 'axios'

const apiOptions = {
  headers: {
    Accept: 'application/json'
  },
  maxRedirects: 0
}

const api = axios.create(apiOptions)

export default api
