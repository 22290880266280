import {
  faHome,
  faTableTennis,
  faList
} from '@fortawesome/free-solid-svg-icons'

const navBarItems = [
  {
    title: 'Home',
    icon: faHome,
    link: '/'
  },
  {
    title: 'leaderboard_title',
    icon: faList,
    link: '/leaderboard'
  },
  {
    title: 'matches_title',
    icon: faTableTennis,
    link: '/matches'
  }
]

export default navBarItems
