import commonItems from './commonItems'
import {
  faPlus
} from '@fortawesome/free-solid-svg-icons'

const navBarItems = [
  ...commonItems,
  {
    title: 'insertMatch_title',
    icon: faPlus,
    link: '/insert'
  }
]

export default navBarItems
