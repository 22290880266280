import React from 'react'
import {FormattedMessage} from 'react-intl'
import {Card, Row, Col, Accordion} from 'react-bootstrap'
import lget from 'lodash.get'

import PropTypes from 'prop-types'

function MatchItem ({match}) {
  const {
    player1,
    player2,
    winner,
    setsInfo,
    newPlayer1Score,
    newPlayer2Score
  } = match

  const matchInfo = setsInfo[0]

  const playerOneItem = winner === player1 ?
    <Col className='winner'>{player1}</Col> : <Col>{player1}</Col>
  const playerTwoItem = winner === player2 ?
    <Col className='winner'>{player2}</Col> : <Col>{player2}</Col>

  return (
    <Card className='match'>
      <Card.Body>
        <Row>
          {playerOneItem}
          <Col>
            {`${lget(matchInfo, ['player1', 'score'], '')} - ${lget(matchInfo, ['player2', 'score'], '')}`}
          </Col>
          {playerTwoItem}
        </Row>
        {(newPlayer1Score && newPlayer2Score) && showMatchDetails(match)}
      </Card.Body>
    </Card>
  )
}

function showMatchDetails (match) {
  const {
    player1,
    player2,
    winner,
    previousPlayer1Score,
    previousPlayer2Score,
    newPlayer1Score,
    newPlayer2Score
  } = match
  return (
    <Accordion className={'mt-3'}>
      <Card>
        <Card.Header>
          <Accordion.Toggle
            as={Card} eventKey='0' variant='link'
          >
            <FormattedMessage id={'details'} />
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey='0'>
          <Card.Body>
            <Row className={'m-1'}>
              <Col>
                {'#'}
              </Col>
              <Col className={`${player1 === winner ? 'winner' : ''}`}>
                {`${player1}`}
              </Col>
              <Col className={`${player2 === winner ? 'winner' : ''}`}>
                {`${player2}`}
              </Col>
            </Row>
            <Row className={'border-bottom border-dark m-1'}>
              <Col className={'d-flex flex-column justify-content-center'}>
                <FormattedMessage id={'newScores'} />
              </Col>
              <Col className={'d-flex flex-column justify-content-center'}>
                {`${newPlayer1Score}`}
              </Col>
              <Col className={'d-flex flex-column justify-content-center'}>
                {`${newPlayer2Score}`}
              </Col>
            </Row>
            <Row className={'border-bottom border-dark m-1'}>
              <Col className={'d-flex flex-column justify-content-center'}>
                <FormattedMessage id={'oldScores'} />
              </Col>
              <Col className={'d-flex flex-column justify-content-center'}>
                {`${previousPlayer1Score}`}
              </Col>
              <Col className={'d-flex flex-column justify-content-center'}>
                {`${previousPlayer2Score}`}
              </Col>
            </Row>
            <Row className={'m-1'}>
              <Col>
                <FormattedMessage id={'pointsDiff'} />
              </Col>
              <Col className={`${player1 === winner ? 'text-success' : 'text-danger'} d-flex flex-column justify-content-center`}>
                {`${newPlayer1Score - previousPlayer1Score}`}
              </Col>
              <Col className={`${player2 === winner ? 'text-success' : 'text-danger'} d-flex flex-column justify-content-center`}>
                {`${newPlayer2Score - previousPlayer2Score}`}
              </Col>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  )
}

MatchItem.propTypes = {
  match: PropTypes.object,
  player1: PropTypes.string,
  player2: PropTypes.string,
  setsInfo: PropTypes.array,
  winner: PropTypes.string
}

export default MatchItem
