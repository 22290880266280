import React from 'react'
import {Card} from 'react-bootstrap'
import useLoadingData from '../../hooks/useLoadingData'

import PropTypes from 'prop-types'
import MatchItem from '../matchItem'

function PlayerInfos ({user}) {
  const {username} = user
  const [data, isError, isLoading, isErrorText] = useLoadingData('users', username, '/matches/', {_l: 5})

  return (
    <div className='playerMatches'>
      <Card className='w-100 p-3 mt-3'>
        {isLoading &&
          <div>{'Loading last matches...'}</div>}
        {isError && data?.length === 0 &&
          <div>{isErrorText}</div>}

        {data?.length === 0 &&

          <div>{`No match found for ${username}`}</div>}

        {
          data?.map((match, index) => {
            return (
              <MatchItem key={index} match={match} />
            )
          })
        }
      </Card>
    </div>
  )
}

PlayerInfos.propTypes = {
  user: PropTypes.object
}

export default PlayerInfos
