import {useEffect, useState} from 'react'
import axios from 'axios'
import {urls} from '../lib/constants'

function useLoadingData (dataType, username = '', url = '', query = {}) {
  const [data, setData] = useState([])
  const [isError, setError] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [isErrorText, setErrorText] = useState('')

  useEffect(() => {
    async function fetchData () {
      try {
        const response = await axios.get(`${urls[dataType]}/${username}${url}`,
          {params: query})
        setData(response.data)
        setError(false)
        setErrorText('')
        return response.data
      } catch (e) {
        setData([])
        setError(true)
        if (e.response.status === 404) {
          setErrorText(setResourceError(dataType, url))
          setLoading(false)
          return data
        } else {
          setErrorText('An error occurred during fetching data...')
          setLoading(false)
          return data
        }
      }
    }
    if (dataType && username) {
      fetchData()
        .then((data) => {
          setData(data)
          setLoading(false)
        })
        .catch(() => setError(true))
    }

    // eslint-disable-next-line
    }, [username])

  return [data, isError, isLoading, isErrorText]
}

function setResourceError (resource, url) {
  switch (resource + url) {
    case 'users':
      return 'Player not found'
    case 'usersmatches':
      return 'Matches not found for this player'
    default:
      return 'Generic error'
  }
}
export default useLoadingData
