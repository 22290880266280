import api from './http'
import qs from 'qs'

const getType = (type, kind) => `${type.toUpperCase()}:${kind.toUpperCase()}`
const pre = (type, metadata) => ({type: getType(type, 'PRE'), ...metadata ? {metadata} : {}})
const success = (type, payload, metadata) => ({type: getType(type, 'SUCCESS'), payload, ...metadata ? {metadata} : {}})
const failed = (type, error) => ({type: getType(type, 'FAILED'), error})
const httpError = (type, error) => ({type: getType(type, 'HTTP_ERROR'), error})

function getFn (TYPE, metadata, callbackOnSuccess) {
  return (url, query) => {
    return async dispatch => {
      dispatch(pre(TYPE, metadata))
      try {
        const queryString = qs.stringify(query, {addQueryPrefix: true})
        const {data} = await api.get(queryString ? `${url}${queryString}` : url)
        dispatch(success(TYPE, data, metadata))
        if (callbackOnSuccess) {
          callbackOnSuccess(data)
        }
      } catch (e) {
        if (e.response) {
          dispatch(httpError(TYPE, e.response))
        } else if (e.request) {
          dispatch(failed(TYPE, e.request))
        } else {
          dispatch(failed(TYPE, e.message))
        }
      }
    }
  }
}

function deleteFn (TYPE, metadata, callbackOnSuccess) {
  return (url, query) => {
    return async dispatch => {
      dispatch(pre(TYPE, metadata))
      try {
        const queryString = qs.stringify(query, {addQueryPrefix: true})
        const {data} = await api.delete(queryString ? `${url}${queryString}` : url)
        dispatch(success(TYPE, data, metadata))
        if (callbackOnSuccess) {
          callbackOnSuccess(data)
        }
      } catch (e) {
        if (e.response) {
          dispatch(httpError(TYPE, e.response))
        } else if (e.request) {
          dispatch(failed(TYPE, e.request))
        } else {
          dispatch(failed(TYPE, e.message))
        }
      }
    }
  }
}

function postFn (TYPE, metadata, callbackOnSuccess) {
  return (url, body) => {
    return async dispatch => {
      dispatch(pre(TYPE, metadata))
      try {
        const {data} = await api.post(url, body)
        dispatch(success(TYPE, data, metadata))
        if (callbackOnSuccess) {
          callbackOnSuccess(data)
        }
      } catch (e) {
        if (e.response) {
          dispatch(httpError(TYPE, e.response))
        } else if (e.request) {
          dispatch(failed(TYPE, e.request))
        } else {
          dispatch(failed(TYPE, e.message))
        }
      }
    }
  }
}

export {
  getFn,
  deleteFn,
  postFn
}
