import React, {useEffect} from 'react'
import {Button} from 'react-bootstrap'
import {useHistory} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import {getUserinfo} from '../actions/userinfo'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {FormattedMessage} from 'react-intl'
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'

function InsertMatchButton ({onClick}) {
  const history = useHistory()
  const dispatch = useDispatch()
  const userinfo = useSelector((state) => state.userinfo)
  const isUserLoggedIn = userinfo && Object.keys(userinfo).length > 0

  useEffect(() => {
    dispatch(getUserinfo())
    // eslint-disable-next-line
    }, [])

  function insertMatch () {
    history.push('/insert')
  }

  const handler = onClick || insertMatch

  if (isUserLoggedIn) {
    return (
      <div className={'insertMatchButton'}>
        <Button
          className={''}
          onClick={handler}
        >
          <FontAwesomeIcon className='ms-2 me-2' icon={faPlus} />
          <FormattedMessage className='navbar-brand' id={'insert_match_title'} />
        </Button>
      </div>
    )
  }

  return <div />
}

InsertMatchButton.propTypes = {
  onClick: PropTypes.func
}

export default InsertMatchButton
