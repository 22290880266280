import {GET_USERINFO, LOGOUT} from '../actions/userinfo'

export default function userinfo (state = {}, {type, payload}) {
  switch (type) {
    case `${GET_USERINFO}:SUCCESS`: {
      return payload
    }
    case `${GET_USERINFO}:FAILED`:
    case `${GET_USERINFO}:HTTP_ERROR`: {
      return {}
    }
    case LOGOUT: {
      return {}
    }
    default:
      return state
  }
}
