import React from 'react'
import {
  NavItem,
  NavLink
} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {FormattedMessage} from 'react-intl'

import PropTypes from 'prop-types'

function NavbarItem ({item, active}) {
  const {title, icon, link} = item
  return (
    <NavItem>
      <NavLink className={active ? 'active' : ''} href={link}>
        <FontAwesomeIcon className='ms-2 me-2' icon={icon} />
        <FormattedMessage className='navbar-brand' id={`${title}`} />
        <span className='visually-hidden'>{'(current)'}</span>
      </NavLink>
    </NavItem>
  )
}

NavbarItem.propTypes = {
  active: PropTypes.bool,
  item: PropTypes.object
}

export default NavbarItem
