import {
  faUser,
  faTools,
  faSignOutAlt
} from '@fortawesome/free-solid-svg-icons'

const dropdownItems = [
  {
    title: 'Profile',
    icon: faUser,
    url: '/users/'
  },
  {
    title: 'Settings',
    icon: faTools,
    url: '/settings'
  },
  {
    title: 'Logout',
    icon: faSignOutAlt,
    url: '/logout'
  }
]

export default dropdownItems
