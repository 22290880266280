import commonItems from './commonItems'
import {
  faUser
} from '@fortawesome/free-solid-svg-icons'

const navBarItems = [
  ...commonItems,
  {
    title: 'login_title',
    icon: faUser,
    link: '/login'
  }
]

export default navBarItems
