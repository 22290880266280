import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import {
  Nav,
  Navbar,
  Image,
  Dropdown
  // Button
} from 'react-bootstrap'
import PropTypes from 'prop-types'

import NavbarItem from './navbarItem'

import loggedUserItems from '../objects/navItems/loggedUserItems'
import notLoggedUserItems from '../objects/navItems/notLoggedUserItems'
import dropdownItems from '../objects/navItems/dropdownItems/items'
import logo from '../assets/images/mia-pong-logo.svg'
import {useLocation, Link} from 'react-router-dom'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

// import {logout} from '../actions/userinfo'
import {useDispatch} from 'react-redux'

import {
  faBars
} from '@fortawesome/free-solid-svg-icons'

function AppNavbar ({isUserLoggedIn, user}) {
  const location = useLocation()
  const navBarItems = isUserLoggedIn ? loggedUserItems : notLoggedUserItems
  const dispatch = useDispatch()

  const items = navBarItems.map((item, index) => {
    return <NavbarItem active={location.pathname === item.link} item={item} key={index} />
  })

  return (
    <Navbar className='customNavbar' collapseOnSelect expand='lg'>
      <Navbar.Brand href='/'>
        <Image className='miaPongBrand' fluid src={logo} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls='responsive-navbar-nav'>
        <FontAwesomeIcon className='ms-2 me-2 navbarToggleIcon' icon={faBars} />
      </Navbar.Toggle>
      <Navbar.Collapse id='responsive-navbar-nav w-50'>
        <Nav className='navItemsContainer'>
          {items}
        </Nav>
        <Nav>
          {isUserLoggedIn &&
            <Dropdown
              className={'nav-item'}
            >
              <Dropdown.Toggle
                id='dropdown-basic'
                style={{
                  backgroundColor: 'transparent',
                  border: '1px transparent',
                  color: '#2c3e50'
                }}
              >
                {user}
              </Dropdown.Toggle>

              <DropdownMenu dispatch={dispatch} user={user} />
            </Dropdown>}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

function DropdownMenu ({user, dispatch}) {
  const profile = dropdownItems[0]
  // const logoutItem = dropdownItems[2]
  return (
    <Dropdown.Menu>
      <Dropdown.Item>
        <div className={'d-flex'}>
          <FontAwesomeIcon className={'m-auto d-flex flex-column justify-content-center'} icon={profile.icon} />
          <Link
            style={{textDecoration: 'none'}}
            to={`${profile.url}${user}`}
          >{profile.title}
          </Link>
        </div>
      </Dropdown.Item>
      <Dropdown.Divider />
      {/*
      <Dropdown.Item>
        <div className={'d-flex'}>
          <FontAwesomeIcon className={'m-auto d-flex flex-column justify-content-center'} icon={logoutItem.icon} />
          <Button
            onClick={() => dispatch(logout())}
            style={{backgroundColor: 'transparent', border: '1px transparent', color: '#2c3e50'}}
          >{logoutItem.title}
          </Button>

        </div>

      </Dropdown.Item>
      */}
    </Dropdown.Menu>
  )
}

DropdownMenu.propTypes = {
  dispatch: PropTypes.func,
  user: PropTypes.string
}

AppNavbar.propTypes = {
  isUserLoggedIn: PropTypes.bool,
  user: PropTypes.string
}

export default AppNavbar
