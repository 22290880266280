const lget = require('lodash.get')

function parseDate (dateString) {
  const date = new Date(dateString)
  let day = date.getDate().toString()
  let month = (date.getMonth() + 1).toString()
  const year = date.getFullYear().toString()

  if (day.length === 1) {
    day = `0${day}`
  }
  if (month.length === 1) {
    month = `0${month}`
  }
  return `${day}-${month}-${year}`
}

function parseInsertRecord (matchRecord) {
  const player1 = lget(matchRecord, ['player1'])
  const player2 = lget(matchRecord, ['player2'])

  const scorePlayer1 = parseInt(lget(matchRecord, ['scorePlayer1']))
  const scorePlayer2 = parseInt(lget(matchRecord, ['scorePlayer2']))

  const winner = scorePlayer1 > scorePlayer2 ? player1 : player2
  const setsWonByPlayer1 = winner === player1 ? 1 : 0
  const setsWonByPlayer2 = winner === player2 ? 1 : 0

  const playedAt = lget(matchRecord, ['playedAt'])

  const setsInfo = [{
    player1: {
      username: player1,
      score: scorePlayer1
    },
    player2: {
      username: player2,
      score: scorePlayer2
    }
  }]
  return {
    player1,
    player2,
    winner,
    setsWonByPlayer1,
    setsWonByPlayer2,
    setsInfo,
    playedAt
  }
}

module.exports = {
  parseDate,
  parseInsertRecord
}
