import React from 'react'

import 'react-datepicker/dist/react-datepicker.css'
import MatchForm from './matchForm'
import PropTypes from 'prop-types'

class InsertMatch extends React.Component {
  render () {
    return (
      <MatchForm history={this.props.history} />
    )
  }
}

InsertMatch.propTypes = {
  history: PropTypes.object
}
export default InsertMatch
