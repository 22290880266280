import axios from 'axios'
import React from 'react'
import {urls} from '../lib/constants'
import MatchItem from './matchItem'
class Matches extends React.Component {
  state = {
    matches: []
  }

  async componentDidMount () {
    const matches = await this.getMatches()
    this.setState({matches})
  }

  async getMatches () {
    let matches
    try {
      matches = (await axios.get(urls.matches)).data
    } catch (error) {
      alert('generic_error')
      matches = []
    }
    return matches
  }

  render () {
    return (
      <div className='matchesContainer'>
        {this.state.matches.map((match, index) => (
          <MatchItem key={index} match={match} />
        ))}
      </div>
    )
  }
}

export default Matches
