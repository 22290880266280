import React from 'react'
import PropTypes from 'prop-types'

function PlayerHeader (props) {
  const {username, name, surname} = props
  return (
    <div className='d-flex flex-column'>
      {!!name && !!surname &&
        <div className='w-100'>
          <div className='font-weight-bold h4'>
            {`${name} ${surname}`}
          </div>
        </div>}
      <div className='w-100 font-weight-bold h4'>
        <div className='font-weight-bold h4' style={{color: '#AED8E6'}}>
          <strong>{`${username}`}</strong>
        </div>
      </div>
    </div>
  )
}

PlayerHeader.propTypes = {
  name: PropTypes.string,
  surname: PropTypes.string,
  username: PropTypes.string
}

export default PlayerHeader
