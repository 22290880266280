import errors from './errors'
import {reducer as reduxFormReducer} from 'redux-form'
import userinfo from './userinfo'
import {users, leaderboard} from './users'

export default {
  errors,
  form: reduxFormReducer,
  userinfo,
  users,
  leaderboard
}
