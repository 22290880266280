import {is, omit} from 'ramda'
import {REMOVE_ERROR_MESSAGE} from '../actions/errors'

const DEFAULT_ERROR_MESSAGE = 'Something went wrong'

const ERROR_INITIAL_STATE = {}
export default function errors (state = ERROR_INITIAL_STATE, {type, error, payload}) {
  const [tag, status] = type.split(':')
  switch (status) {
    case 'FAILED': {
      return {
        ...state,
        [tag]: is(String, error) ? error : (payload ? `Error with payload: ${payload}` : 'Generic error')
      }
    }
    case 'HTTP_ERROR': {
      return {
        ...state,
        [tag]: is(Object, error.data) ? (error.data.message || DEFAULT_ERROR_MESSAGE) : (error.data || DEFAULT_ERROR_MESSAGE)
      }
    }
    case 'SUCCESS': {
      return omit([tag], state)
    }
    default: {
      if (type === REMOVE_ERROR_MESSAGE) {
        return omit([payload], state)
      }
      return state
    }
  }
}
