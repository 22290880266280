import React, {Component} from 'react'
import {
  Matches,
  Leaderboard,
  Profile,
  Home,
  Navbar as AppNavbar,
  Login
} from './components'

import 'bootstrap/dist/css/bootstrap.min.css'
import PropTypes from 'prop-types'
import './assets/styles/App.css'
import {Route, Switch} from 'react-router'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {getUserinfo} from './actions/userinfo'
import {fetchUsers} from './actions/users'
import InsertMatch from './components/insertMatch'
import {ToastProvider} from 'react-toast-notifications'
import {BrowserRouter} from 'react-router-dom'

class App extends Component {
  static propTypes = {
    fetchUsers: PropTypes.func,
    getUserinfo: PropTypes.func,
    userinfo: PropTypes.object,
    users: PropTypes.array
  }

  async componentDidMount () {
    const {getUserinfo, fetchUsers} = this.props
    await getUserinfo()
    await fetchUsers()
  }

  render () {
    const {userinfo} = this.props
    const isUserLoggedIn = userinfo && Object.keys(userinfo).length > 0
    return (
      <ToastProvider>
        <div className='App vh-100'>
          <BrowserRouter>
            <AppNavbar className='App-header' isUserLoggedIn={isUserLoggedIn} user={userinfo?.nickname} />
            <div className='App-body w-100 mw-100'>
              <Switch>
                <Route exact path='/' render={() => <Home users={this.props.users} />} />
                <Route component={Leaderboard} exact path='/leaderboard' />
                <Route component={Matches} exact path='/matches' />
                <Route component={Profile} exact path='/users/:username' />
                {isUserLoggedIn ?
                  <Route component={InsertMatch} exact path='/insert' /> :
                  <Route component={Login} exact path='/login' />}
              </Switch>
            </div>
          </BrowserRouter>
        </div>
      </ToastProvider>
    )
  }
}

function mapStateToProps (state) {
  return {
    userinfo: state.userinfo,
    users: Object.values(state.users)
  }
}

function mapDispatchToProps (dispatch) {
  return {
    getUserinfo: bindActionCreators(getUserinfo, dispatch),
    fetchUsers: bindActionCreators(fetchUsers, dispatch)
  }
}

export const AppComponent = App

export default connect(mapStateToProps, mapDispatchToProps)(App)
