import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {FormattedMessage} from 'react-intl'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'

import {fetchLeaderboard} from '../actions/users'
import InsertMatchButton from './insertMatchButton'

class Leaderboard extends React.Component {
  static propTypes = {
    fetchLeaderboard: PropTypes.func,
    leaderboard: PropTypes.array
  }

  async componentDidMount () {
    const {fetchLeaderboard} = this.props
    await fetchLeaderboard()
  }

  render () {
    const {nickname: user} = this.props.userinfo
    const {leaderboard} = this.props

    if (!leaderboard) {
      return <div>{'Loading...'}</div>
    }

    let playerPosition = 0
    return (
      <div className='leaderboard mt-3'>
        <div className={'insertMatchInLeaderboard hideOnDesktop'}>
          <InsertMatchButton />
        </div>
        <div>
          <table className='table w-100 table-hover align-top'>
            <thead className='w-100'>
              <tr className=''>
                <th id='rank'>
                  <FormattedMessage id='#' />
                </th>
                <th id='username'>
                  <FormattedMessage id='username' />
                </th>
                <th id='name'>
                  <FormattedMessage id='name' />
                </th>
                <th id='playedMatches'>
                  <FormattedMessage id='playedMatches' />
                </th>
                <th id='wonMatches'>
                  <FormattedMessage id='wonMatches' />
                </th>
                <th id='lostMatches'>
                  <FormattedMessage id='lostMatches' />
                </th>
                <th id='score'>
                  <FormattedMessage id='score' />
                </th>
              </tr>
            </thead>
            <tbody>
              {leaderboard.map((player, index) => {
                playerPosition++
                const positionRankStyle = getPositionRankColor(playerPosition)
                const currentUser = player.username === user ? 'currentUserInTable' : ''
                const currentUserColor = currentUser === 'currentUserInTable' ? '#AED8E6' : 'black'
                const playerName = player.firstName && player.lastName ? `${player.firstName} ${player.lastName}` : ' -- '
                return (
                  <tr
                    key={player.id}
                  >
                    <td className='rank'>
                      <div className={positionRankStyle}>
                        {playerPosition}
                      </div>
                    </td>
                    <td className={`${currentUser} username`}>
                      <Link
                        style={{textDecoration: 'none', color: currentUserColor}}
                        to={`/users/${player.username}`}
                      >{player.username}
                      </Link>
                    </td>
                    <td className='name'>
                      {`${playerName}`}
                    </td>
                    <td className='playedMatches'>
                      {player.playedMatches ?? '-'}
                    </td>
                    <td className='wonMatches'>
                      {player.wonMatches ?? '-'}
                    </td>
                    <td className='lostMatches'>
                      {player.lostMatches ?? '-'}
                    </td>
                    <td className='score'>
                      {player.score}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

Leaderboard.propTypes = {
  userinfo: PropTypes.object
}

function getPositionRankColor (rank) {
  switch (rank) {
    case 1:
      return 'firstPlace podium'
    case 2:
      return 'secondPlace podium'
    case 3:
      return 'thirdPlace podium'
    default:
      return ''
  }
}

function mapStateToProps (state) {
  return {
    userinfo: state.userinfo,
    leaderboard: state.leaderboard
  }
}

function mapDispatchToProps (dispatch) {
  return {
    fetchLeaderboard: bindActionCreators(fetchLeaderboard, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Leaderboard)
