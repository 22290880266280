import {
  FETCH_USERS,
  FETCH_LEADERBOARD,
  FETCH_LEADERBOARD_ERROR
} from '../lib/usersConstants'
import {urls} from '../lib/constants'
import axios from 'axios'

export const fetchUsers = () => async dispatch => {
  const users = (await axios.get(urls.users)).data
  dispatch({type: FETCH_USERS, payload: users})
}

export const fetchLeaderboard = () => async dispatch => {
  try {
    const leaderboard = (await axios.get(urls.leaderboard))
      .data
      .sort((playerOne, playerTwo) => {
        return playerTwo.score - playerOne.score
      })
    dispatch({type: FETCH_LEADERBOARD, payload: leaderboard})
  } catch (e) {
    dispatch({type: FETCH_LEADERBOARD_ERROR, payload: []})
  }
}
