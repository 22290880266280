import {urls} from '../lib/constants'
import {getFn} from '../lib/httpUtilities'

export const GET_USERINFO = 'GET_USERINFO'
export const LOGOUT = 'LOGOUT'

export const getUserinfo = (query) => getFn(GET_USERINFO)(urls.userinfo, query)

export const logout = () => {
  return {type: 'LOGOUT', payload: {}}
}
