import React from 'react'
import logo from '../assets/images/mia-pong-logo.svg'
import {
  Image,
  Button
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import {
  Field,
  Form
} from 'react-final-form'
import {FormattedMessage} from 'react-intl'
import ReactSelectAdapter from './reactSelectAdapter'
import {useHistory} from 'react-router'

function Home (props) {
  const history = useHistory()
  const {users} = props

  function submitUser (values) {
    const {user} = values
    history.push(`/users/${user.label}`)
  }

  const formattedUsers = users.map((user) => {
    const {name, surname} = user
    return {label: user.username, value: {name: `${name || ''} ${surname || ''}`}}
  })

  return (
    <div>
      <div className='d-flex flex-column justify-content-center w-100'>
        <Image className='m-auto miaPongImage' fluid src={logo} />
        <Form
          initialValues={{}}
          onSubmit={submitUser}
          render={({handleSubmit, form, submitting, pristine}) => (
            <form
              className={'home-player-form'}
              onSubmit={event => {
                handleSubmit(event)
                form.reset()
              }}
            >
              <div className=''>
                <Field
                  component={ReactSelectAdapter}
                  getOptionLabel={option => `${option.label}️ - ${option.value.name}`}
                  name='user'
                  options={formattedUsers}
                  placeholder={<FormattedMessage id={'search-user'} />}
                  type='text'
                />
              </div>
              <div className='buttons mt-3'>
                <Button disabled={submitting || pristine} type='submit'>
                  {'Submit'}
                </Button>
              </div>
            </form>
          )}
        />
      </div>
    </div>
  )
}

Home.propTypes = {
  users: PropTypes.array
}

export default Home
