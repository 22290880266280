import {
  FETCH_LEADERBOARD,
  FETCH_USERS,
  FETCH_LEADERBOARD_ERROR
} from '../lib/usersConstants'
import _ from 'lodash'

export function users (state = {}, {type, payload}) {
  switch (type) {
    case FETCH_USERS: {
      return {...state, ..._.mapKeys(payload, 'username')}
    }
    default:
      return state
  }
}

export function leaderboard (state = [], {type, payload}) {
  switch (type) {
    case FETCH_LEADERBOARD: {
      return payload
    }
    case FETCH_LEADERBOARD_ERROR: {
      return state
    }
    default:
      return state
  }
}
