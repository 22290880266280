import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {fetchLeaderboard} from '../actions/users'
import {
  faArrowUp as upperPlayerArrow,
  faArrowDown as lowerPlayerArrow
} from '@fortawesome/free-solid-svg-icons'

import PropTypes from 'prop-types'

import _ from 'lodash'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
function PlayerLeaderboardView ({user}) {
  const dispatch = useDispatch()
  const leaderboard = useSelector((state) => state.leaderboard)
  const formattedLeaderboard = cutLeaderboard(user, leaderboard)
  useEffect(() => {
    dispatch(fetchLeaderboard())
    // eslint-disable-next-line
  }, [])

  if (formattedLeaderboard.length === 0) { return <div>{'Fetching position...'}</div> }
  if (formattedLeaderboard.length === 2) {
    const player = formattedLeaderboard.filter((p) => {
      return p.isFirst || p.isLast
    })[0]
    if (player.isFirst) {
      return (
        <div className={'flex-grow-1 h-100 d-flex'}>
          <div className={'m-auto align-self-center'}>
            {'Primo posto 🚀 con margine di '}
            <span style={{color: 'lightgreen'}}>{player.lowerDistance}</span> {' punti da '}
            <span style={{color: 'lightgreen'}}>{player.following}</span>
          </div>
        </div>
      )
    }
    if (player.isLast) {
      return (
        <div className={' flex-grow-1 h-100 d-flex'}>
          <div className={'m-auto align-self-center'}>
            {'Ultimo posto 😭 mancano ancora '}
            <span style={{color: 'orangered'}}>{player.upperDistance}</span> {' punti per raggiungere '}
            <span style={{color: 'orangered'}}>{player.previous}</span>
          </div>
        </div>
      )
    }
  }
  return (
    <div className='m-auto'>
      <div className={'leaderboardInsight border-bottom border-dark w-100'}>
        <strong>{'Leaderboard insight'}</strong>
      </div>
      {formattedLeaderboard.map((player, index) => {
        const currentUser = player.username === user ? 'currentUserInTable' : ''
        const whichPlayer = getPlayer(index)
        const icon = whichPlayer === 'upperPlayer' ?
          upperPlayerArrow : lowerPlayerArrow
        return (
          <div
            className={'d-flex justify-content-between leaderboardInsightItem'}
            key={player.id}
          >
            <div className={`flex-grow-1 ${whichPlayer}`}>
              {index !== 1 &&
                <FontAwesomeIcon className='ms-2 me-2' icon={icon} size='md' />}
              {index === 1 &&
                <div className='playerDistances'>
                  <span className={'align-self-start upperPlayer'}>{`-${player.upperDistance} points`}</span>
                  <span className={'align-self-end lowerPlayer'}>{`+${player.lowerDistance} points`}</span>
                </div>}
            </div>
            <div className={`${currentUser} username w-100`}>
              {player.username}
            </div>
            <div className={`score flex-grow-1 m-auto ${whichPlayer}`}>
              {player.score}
            </div>
          </div>

        )
      })}
    </div>
  )
}

function cutLeaderboard (user, leaderboard) {
  if (leaderboard.length === 0 || !user) { return [] }
  const userPosition = _.findIndex(leaderboard, (u) => {
    return u.username === user
  })
  if (userPosition === 0) {
    const newLeaderboard = leaderboard.slice(0, 2)
    return calculateDistances(newLeaderboard, 'first')
  } else if (userPosition === leaderboard.length - 1) {
    const newLeaderboard = leaderboard.slice(-2)
    return calculateDistances(newLeaderboard, 'last')
  } else {
    const newLeaderboard = leaderboard.slice(userPosition - 1, userPosition + 2)
    return calculateDistances(newLeaderboard, 'mid')
  }
}

function calculateDistances (leaderboard, currentPlayerIndex) {
  switch (currentPlayerIndex) {
    case 'first':
      leaderboard[0].lowerDistance = leaderboard[0].score - leaderboard[1].score
      leaderboard[0].isFirst = true
      leaderboard[0].following = leaderboard[1].username
      break
    case 'mid':
      leaderboard[1].upperDistance = leaderboard[0].score - leaderboard[1].score
      leaderboard[1].lowerDistance = leaderboard[1].score - leaderboard[2].score
      break
    default:
      leaderboard[1].upperDistance = leaderboard[0].score - leaderboard[1].score
      leaderboard[1].isLast = true
      leaderboard[1].previous = leaderboard[0].username
      break
  }

  return leaderboard
}

function getPlayer (index) {
  switch (index) {
    case 0:
      return 'upperPlayer'
    case 1:
      return ''
    default:
      return 'lowerPlayer'
  }
}

PlayerLeaderboardView.propTypes = {
  user: PropTypes.string
}

export default PlayerLeaderboardView
