import React, {useEffect} from 'react'

function Login () {
  useEffect(() => {
    window.location.replace('/api/web-login/login')
  }, [])

  return <></>
}

export default Login
