import Select from 'react-select'
import PropTypes from 'prop-types'
import React from 'react'

const ReactSelectAdapter = ({input, ...rest}) => (
  <Select
    {...input}
    {...rest}
  />
)

ReactSelectAdapter.propTypes = {
  input: PropTypes.array
}

export default ReactSelectAdapter
